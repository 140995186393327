export const GRIEVANCE_FIELDS = `
  id
  type
  associate {
    id
    name
  }
  occurrence {
    id
    date
  }
  workRuleViolation {
    id
    date
  }
  contractGrievanceType
  status
  startDate
  currentStep
  createdBy {
    id
    name
    email
  }
  createdAt
  updatedAt
  resolvedAt
  resolvedBy {
    id
    name
    email
  }
  resolvedNotes
  resolvedDocuments
  steps {
    id
    step
    notes
    documents
    startedAt
    expiresAt
    completedAt
    completedBy {
      id
      name
    }
    completedNotes
    completedDocuments
    createdAt
    createdBy {
      id
      name
      email
    }
  }
  events {
    id
    type
    data
    createdAt
    user {
      id
      name
    }
    message
  }
`

export const GRIEVANCE_QUERY = gql`
  query GrievanceQuery ($id: String!) {
    grievance(id: $id) {
      ${GRIEVANCE_FIELDS}
    }
  }
`

export const CREATE_GRIEVANCE_MUTATION = gql`
  mutation createGrievance($input: CreateGrievanceInput!) {
    createGrievance(input: $input) {
      id
      userErrors {
        field
        message
      }
    }
  }
`

export const ESCALATE_GRIEVANCE_STEP_MUTATION = gql`
  mutation escalateGrievanceStep($grievanceId: String!, $step: String!) {
    escalateGrievanceStep(grievanceId: $grievanceId, step: $step) {
      ${GRIEVANCE_FIELDS}
    }
  }
`

export const UPDATE_GRIEVANCE_STEP_MUTATION = gql`
  mutation updateGrievanceStep($grievanceId: String!, $step: String!, $input: UpdateGrievanceStepInput!) {
    updateGrievanceStep(grievanceId: $grievanceId, step: $step, input: $input) {
      ${GRIEVANCE_FIELDS}
    }
  }
`

export const RESOLVE_GRIEVANCE_MUTATION = gql`
  mutation resolveGrievance($grievanceId: String!, $resolvedAt: DateTime, $notes: String, $documents: [String!]!) {
    resolveGrievance(grievanceId: $grievanceId, notes: $notes, resolvedAt: $resolvedAt, documents: $documents) {
      ${GRIEVANCE_FIELDS}
    }
  }
`
