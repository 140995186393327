export const capitalize = (str) => {
  if (!str) return str
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const userDisplayString = (user) => {
  return (
    (user &&
      (user.associate ? user.associate.name : user.name || user.email)) ||
    ''
  )
}

export const costCenterAddressString = (costCenter) => {
  if (costCenter.address) {
    return `${costCenter.address.address1}, ${costCenter.address.city}, ${costCenter.address.state} ${costCenter.address.zip}`
  }
  return 'No address on file'
}

export const initials = (user) => {
  const str = userDisplayString(user)
  const parts = str.split(' ')
  if (parts.length === 1) {
    return str.charAt(0).toUpperCase()
  }
  if (parts.length > 1) {
    return `${parts[0].charAt(0).toUpperCase()}${parts[1]
      .charAt(0)
      .toUpperCase()}`
  }
  return null
}

export const placeholders = {
  employeeId: 'e.g. 000123456',
  firstName: 'Jane',
  lastName: 'Doe',
  name: 'Jane Doe',
  phone: 'e.g. 613-555-1010',
}

export const handleize = (str, preferDash = false) => {
  return str
    .replace(/[^a-zA-Z0-9]/g, preferDash ? '-' : '_')
    .replace(/_+/g, '_')
    .replace(/_$/, '')
    .replace(/^_/, '')
    .toLowerCase()
}

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return ''
  // Remove the leading '+' from the phone number
  var rawNumber = phoneNumber.slice(1)

  // Split the phone number into its constituent parts
  var countryCode = rawNumber.slice(0, 1)
  var areaCode = rawNumber.slice(1, 4)
  var firstPart = rawNumber.slice(4, 7)
  var secondPart = rawNumber.slice(7, 11)

  // Format the phone number
  var formattedNumber = `(${areaCode}) ${firstPart}-${secondPart}`

  // Add the country code if it's not 1 (for US)
  if (countryCode !== '1') {
    formattedNumber = '+' + countryCode + ' ' + formattedNumber
  }

  return formattedNumber
}

export const absenceReasonDisplayString = (absenceReason) => {
  if (!absenceReason) return ''
  absenceReason = absenceReason.replace(/_/g, ' ')
  if (absenceReason === 'fmla') return 'FMLA'
  return capitalize(absenceReason)
}
