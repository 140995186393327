const PrintLayout = ({ children }) => {

  return (
    <>
      {children}
    </>
  )
}

export default PrintLayout
