import React from 'react'

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import i18next from 'i18next'
import 'dayjs/locale/es'

import en_json from 'src/locales/en.json'
import es_json from 'src/locales/es.json'

dayjs.extend(localizedFormat)

const LocaleContext = React.createContext()

const LocaleProvider = ({ children }) => {
  const [initialized, setInitialized] = React.useState(false)
  const [enInstance, setEnInstance] = React.useState(null)
  const [esInstance, setEsInstance] = React.useState(null)

  const locale = new URLSearchParams(window.location.search).get('locale')
  // const locale = 'fr-FR';

  const preferredLang = (locale || 'en').split('-')[0]
  const supportedLanguages = ['en', 'fr']

  React.useEffect(() => {
    ;(async () => {
      let resources = {
        en: {
          translation: en_json,
        },
        es: {
          translation: es_json,
        },
      }

      i18next.init({
        fallbackLng: 'en',
        interpolation: { escapeValue: false }, // React already does escaping
        lng: preferredLang,
        resources,
      })

      const _enInstance = i18next.createInstance()
      _enInstance.init({
        fallbackLng: 'en',
        interpolation: { escapeValue: false }, // React already does escaping
        lng: 'en',
        resources,
      })
      setEnInstance(_enInstance)

      const _esInstance = i18next.createInstance()
      _esInstance.init({
        fallbackLng: 'en',
        interpolation: { escapeValue: false }, // React already does escaping
        lng: 'es',
        resources,
      })
      setEsInstance(_esInstance)

      setInitialized(true)
    })()
  }, [])

  const i18nInstance = (language) => {
    if (language === 'en-US') {
      return enInstance
    } else if (language === 'es-US') {
      return esInstance
    } else {
      return i18next
    }
  }

  const t = (instance, key, args, defaultValue) => {
    if (instance && (instance.exists(key) || !defaultValue)) {
      return instance.t(key, args)
    } else {
      return defaultValue
    }
  }

  const localeContext = {
    language: preferredLang,
    t: (key, args, defaultValue) => t(i18next, key, args, defaultValue),
    l_t: (language) => (key, args, defaultValue) =>
      t(i18nInstance(language), key, args, defaultValue),
    formatDateAndTime: (date, format = 'lll') =>
      dayjs(date)
        .locale(preferredLang)
        .format(format || 'lll'),
    l_formatDateAndTime:
      (language) =>
      (date, format = 'lll') =>
        dayjs(date)
          .locale(language || preferredLang)
          .format(format || 'lll'),
  }

  if (!initialized) {
    return ''
  }

  return (
    <LocaleContext.Provider value={localeContext}>
      {children}
    </LocaleContext.Provider>
  )
}

const useLocale = () => React.useContext(LocaleContext)

export { LocaleProvider, useLocale }
