import styled from '@emotion/styled'

export const AlumGrid = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  gap: 16px;
`

export const NavigationSectionHeading = styled.p`
  padding-bottom: 6px;
  font-size: 13px;
`

export const FilterContainer = styled.div`
  padding: var(--p-space-4);

  @media (min-width: 490px) {
    padding: var(--p-space-5);
  }
`

export const CardSubtitle = styled.div`
  padding: var(--p-space-2) var(--p-space-4) 0 var(--p-space-4);

  @media (min-width: 490px) {
    padding-left: var(--p-space-5);
    padding-right: var(--p-space-5);
  }
`

export const CardFooter = styled.div`
  padding: var(--p-space-0) var(--p-space-4) var(--p-space-4) var(--p-space-4);

  @media (min-width: 490px) {
    padding: var(--p-space-0) var(--p-space-5) var(--p-space-5) var(--p-space-5);
  }
`;

export const PageActionContainer = styled.div`
  margin-top: 24px;
  padding-top: 24px;
  padding-bottom: 48px;
`

export const FloatingSummaryPageContainer = styled.div`
  @media (min-width: 1028px) {
    .Polaris-Layout__Section--oneThird {
      position: sticky;
      top: 74px;
    }
  }
`

export const CheckboxDisclosureContent = styled.div`
  position: relative;
  padding-left: 30px;

  ${(props) => props.padded && `
    margin-bottom: 12px;
  `};

  @media (min-width: 768px) {
    padding-left: 28px;
  }

  &:after {
    position: absolute;
    left: 9px;
    top: 0;
    content: '';
    width: 1px;
    height: 100%;
    background: rgba(0,0,0,0.1);
  }
`

export const TextCenter = styled.div`
  text-align: center;
`

export const TextRight = styled.div`
  text-align: right;
`

export const JustifyCenter = styled.div`
  display: flex;
  justify-content: center;
`

export const JustifyRight = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ProgressBarWrapper = styled.div`
  position: relative;
  padding-bottom: 12px;

  .Polaris-ProgressBar--colorHighlight {
    --pc-progress-bar-indicator: ${(props) => props.progressBarColor};
  }
`

export const ProgressBarNotch = styled.div`
  position: absolute;
  pointer-events: none;

  &:first-of-type, &:last-of-type {
    opacity: 0;
    transform: none;
  }

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 16px;
    background: rgba(0, 0, 0, 0.2);
    left: 0;
    bottom: 100%;
  }
`

export const ProgressBarNotchWrapper = styled.div`
`

export const CounselingThresholdContainer = styled.div`
`

export const CounselingThresholdItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`

export const ThresholdColorIndicator = styled.div`

  ${(props) => props.size === 'small' ? `
    --size: 10px;
  ` : `
    --size: 15px;
  `};
  content: '';
  left: 0;
  top: 2px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  margin-right: 8px;
  background: ${(props) => props.color};
`

export const SortableTable = styled.div`
  th {
    display: none;
  }
  th:nth-child(1), td:nth-child(1) {
    width: 36px !important;
    padding-right: 0 !important;

    .Polaris-Choice__Control {
      margin-right: 0;
    }
  }
  tbody tr:first-of-type {
    border-top: none;
  }
  .Polaris-IndexTable__ScrollBarContainer {
    display: none;
  }
`;
