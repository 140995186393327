import React from 'react'

const PermissionsContext = React.createContext()

const PermissionsProvider = ({ currentUser, children }) => {
  const permissionsContext = {
    hasRole: (roles) => {
      if (!currentUser) {
        return false
      }

      if (typeof roles === 'string') {
        roles = [roles]
      }

      const currentUserRoles = currentUser?.roles || []

      if (currentUserRoles.some((r) => roles.includes(r))) {
        return true
      }

      return false
    },
    currentUser,
  }

  return (
    <PermissionsContext.Provider value={permissionsContext}>
      {children}
    </PermissionsContext.Provider>
  )
}

const usePermissions = () => React.useContext(PermissionsContext)

export { PermissionsProvider, usePermissions }
