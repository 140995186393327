import { useEffect, useState } from 'react'

import { Frame, Layout, Loading, Page, Stack, TopBar } from '@shopify/polaris'
import { LogOutMinor } from '@shopify/polaris-icons'

import { navigate, routes, useLocation } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'
import { Toaster } from '@redwoodjs/web/toast'

import { useAuth } from 'src/auth'
import { OnboardingHeader } from 'src/components/onboarding/OnboardingHeader'
import GATracker from 'src/components/shared/GATracker'
import { APP_USER_QUERY } from 'src/graphql/users'
import { ROLES } from 'src/helpers/roles'
import { initials, userDisplayString } from 'src/helpers/strings'

import { PermissionsProvider, usePermissions } from '../../contexts/permissions'

import logo from './logo.png'

const OnboardingLayout = ({ loading, user, children }) => {
  const { logOut, currentUser } = useAuth()
  const { hasRole } = usePermissions()
  const location = useLocation()

  const [showUserMenu, setShowUserMenu] = useState(false)
  const toggleShowUserMenu = () => setShowUserMenu(!showUserMenu)

  const associate = user?.associate

  const userMenuMarkup = !loading && (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            {
              content: 'Log out',
              icon: LogOutMinor,
              onAction: logOut,
            },
          ],
        },
      ]}
      name={userDisplayString(user)}
      detail={associate?.costCenter?.name}
      initials={initials(user)}
      open={showUserMenu}
      onToggle={toggleShowUserMenu}
    />
  )

  const isNotSupervisor = hasRole([
    ROLES.MANAGER,
    ROLES.ACCOUNT_ADMIN,
    ROLES.ADMIN,
  ])

  const accountsRequiringSetup = currentUser?.adminOnAccounts?.filter(
    (account) => !account.onboardingCompletedAt
  )
  const askForOccurrences = isNotSupervisor && !hasRole(ROLES.ADMIN)
  const totalStepCount =
    accountsRequiringSetup.length + (askForOccurrences ? 1 : 0)

  let currentStep = 1
  let title

  if (location.pathname === '/onboarding') {
    title = 'Complete your profile'
  } else if (location.pathname === '/onboarding/occurrences') {
    currentStep = 2
    title = 'Input previous associate occurrences'
  } else {
    currentStep = 2
    title = 'Edit account details'
  }

  let Container

  if (!location.pathname.startsWith('/onboarding/occurrences')) {
    Container = ({ children: _children }) => (
      <div style={{ paddingTop: '24px' }}>
        <Page
          narrowWidth={!location.pathname.startsWith('/onboarding/occurrences')}
          fullWidth={location.pathname.startsWith('/onboarding/occurrences')}
        >
          <Layout sectioned>
            <Stack spacing="loose" vertical>
              <OnboardingHeader
                title={title}
                subtitle={`Step ${currentStep} of ${totalStepCount + 1}`}
                backAction={
                  currentStep > 1 ? () => navigate('/onboarding') : undefined
                }
              />
              {_children}
            </Stack>
          </Layout>
        </Page>
      </div>
    )
  } else {
    Container = ({ children: _children }) => children
  }

  return (
    <Frame
      topBar={<TopBar userMenu={userMenuMarkup} />}
      logo={{
        topBarSource: logo,
        url: '/',
        accessibilityLabel: 'TrackPath',
      }}
    >
      <GATracker />
      {loading && <Loading />}
      <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />
      <Container>{children}</Container>
    </Frame>
  )
}

const OnboardingLayoutContainer = ({ children }) => {
  const location = useLocation()
  const { loading, data } = useQuery(APP_USER_QUERY)

  const user = data?.currentUser

  useEffect(() => {
    if (!user) return

    if (!user.hasCompletedOnboarding && location.pathname !== '/onboarding') {
      navigate(routes.onboarding())
    }

    const accountsNeedingOnboarding = user.adminOnAccounts.filter(
      (account) => !account.onboardingCompletedAt
    )

    if (
      accountsNeedingOnboarding?.length > 0 &&
      user.hasCompletedOnboarding &&
      location.pathname !== '/onboarding' &&
      !location.pathname.startsWith('/onboarding/account')
    ) {
      navigate(
        routes.onboardingAccount({ id: accountsNeedingOnboarding[0].id })
      )
    }
  }, [user])

  if (loading) {
    return ''
  }

  return (
    <PermissionsProvider currentUser={user}>
      <OnboardingLayout loading={loading} user={user}>
        {children}
      </OnboardingLayout>
    </PermissionsProvider>
  )
}

export default OnboardingLayoutContainer
