export const TRACK_FIELDS = `
  id
  title
  enabled
  occurrences {
    id
    occurrenceTypeId
    occurrenceType {
      id
      name
      key
      points
    }
  }
  counselingLevels {
    id
    name
    points
    appliesTo
  }
  counselingActions {
    counselingLevelId
    counselingLevel {
      id
      name
      points
      appliesTo
    }
    action
  }
  deletedAt
`

export const TRACK_QUERY = gql`
  query TrackQuery ($id: String!) {
    track(id: $id) {
      ${TRACK_FIELDS}
    }
  }
`

export const CREATE_TRACK_MUTATION = gql`
  mutation createTrack($input: TrackInput!) {
    createTrack(input: $input) {
      id
      userErrors {
        field
        message
      }
    }
  }
`

export const UPDATE_TRACK_MUTATION = gql`
  mutation updateTrack($id: String!, $input: TrackInput!) {
    updateTrack(id: $id, input: $input) {
      id
      userErrors {
        field
        message
      }
    }
  }
`

export const DELETE_TRACK_MUTATION = gql`
  mutation deleteTrack($id: String!) {
    deleteTrack(id: $id) {
      id
    }
  }
`
