import { useEffect } from 'react'

import { useLocation } from '@redwoodjs/router'

import { useAuth } from 'src/auth'

const GATracker = () => {
  const { pathname } = useLocation()
  const { currentUser } = useAuth()

  useEffect(() => {
    if (window && window.gtag && currentUser) {
      window.gtag('set', { user_id: currentUser.id })
      window.gtag('event', 'page_view', { page_path: pathname })
    }
  }, [pathname, currentUser])

  return null
}

export default GATracker
