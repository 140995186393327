import { Toaster } from '@redwoodjs/web/toast'
import GATracker from 'src/components/shared/GATracker'

const PlainLayout = ({ children }) => {

  return (
    <div id="PlainLayout">
      <GATracker />
      <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />
      {children}
    </div>
  )
}

const PlainLayoutContainer = ({ children }) => {
  return (
    <PlainLayout>
      {children}
    </PlainLayout>
  )
}

export default PlainLayoutContainer
