import { ASSOCIATE_FIELDS } from './associates'

export const USER_SEARCH_QUERY = gql`
  query SearchUsers(
    $searchValue: String!
    $role: [String!]
    $accountIds: [String!]
    $costCenterIds: [String!]
  ) {
    searchUsers(
      searchValue: $searchValue
      role: $role
      accountIds: $accountIds
      costCenterIds: $costCenterIds
    ) {
      id
      email
      associate {
        externalId
        name
        firstName
        lastName
      }
    }
  }
`

export const USERS_QUERY = gql`
  query UsersQuery($input: UserPageInput!) {
    userPage(input: $input) {
      users {
        id
        externalId
        firstName
        lastName
        name
        email
        lastLoginAt
        lastRequestAt
        invitedAt
        roles
        associate {
          id
          externalId
          name
          firstName
          lastName
          costCenters {
            id
            name
            externalId
            deletedAt
            account {
              id
              name
            }
          }
        }
        status
        adminOnAccounts {
          id
          name
        }
        managedCostCenters {
          id
          name
          externalId
          deletedAt
          account {
            id
            name
            stripeSubscriptionId
            externalBilling
            stripePlanName
          }
        }
        adminOnCostCenters {
          id
          name
          externalId
          deletedAt
          account {
            id
            name
          }
        }
      }
      hasNextPage
      hasPreviousPage
    }
  }
`

export const USER_QUERY = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      id
      organizationId
      organization {
        id
        name
        subdomain
        allowedEmailDomains
      }
      externalId
      firstName
      lastName
      name
      email
      lastLoginAt
      roles
      invitedAt
      inviteUrl
      status
      demoUser
      adminOnAccounts {
        id
        name
        stripeSubscriptionId
        externalBilling
        stripePlanName
        policyRuleSets {
          grievanceTrackingEnabled
        }
      }
      managedCostCenters {
        id
        name
        deletedAt
        account {
          id
          name
          stripeSubscriptionId
          externalBilling
          stripePlanName
          policyRuleSets {
            grievanceTrackingEnabled
          }
        }
      }
      adminOnCostCenters {
        id
        name
        externalId
        deletedAt
        account {
          id
          name
          stripeSubscriptionId
          externalBilling
          stripePlanName
          policyRuleSets {
            grievanceTrackingEnabled
          }
        }
      }
      associate {
        ${ASSOCIATE_FIELDS}
      }
    }
  }
`

export const USER_COMMUNICATIONS_QUERY = gql`
  query GetCommunications($id: String!, $page: Int!) {
    communicationPage(userId: $id, page: $page) {
      communications {
        id
        template
        subject
        body
        to
        from
        associate {
          ${ASSOCIATE_FIELDS}
        }
        createdAt
      }
      hasNextPage
      hasPreviousPage
    }
  }
`

export const APP_USER_QUERY = gql`
  query AppCurrentUserQuery {
    currentUser {
      id
      email
      organizationId
      organization {
        id
        name
        subdomain
        allowedEmailDomains
      }
      roles
      firstName
      lastName
      externalId
      phone
      activeRequestedCounselingsCount
      hasCompletedOnboarding
      disabledLevel1Users
      adminOnAccounts {
        id
        onboardingCompletedAt
        stripeSubscriptionId
        externalBilling
        stripePlanName
        policyRuleSets {
          grievanceTrackingEnabled
        }
        coverageShiftSettings {
          enabled
          createLevels
        }
      }
      managedCostCenters {
        id
        name
        deletedAt
        account {
          id
          name
          stripeSubscriptionId
          externalBilling
          stripePlanName
          policyRuleSets {
            grievanceTrackingEnabled
          }
          coverageShiftSettings {
            enabled
            createLevels
          }
        }
      }
      adminOnCostCenters {
        id
        name
        externalId
        deletedAt
        account {
          id
          name
          stripeSubscriptionId
          externalBilling
          stripePlanName
          policyRuleSets {
            grievanceTrackingEnabled
          }
          coverageShiftSettings {
            enabled
            createLevels
          }
        }
      }
      associate {
        id
        externalId
        firstName
        lastName
        phoneNumber
        name
        costCenters {
          name
        }
      }
    }
  }
`

export const USER_CREATE_MUTATION = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      userErrors {
        field
        message
      }
    }
  }
`

export const USER_UPDATE_MUTATION = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      skippedAssociateLanguageSelection
      userErrors {
        field
        message
      }
    }
  }
`

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      id
      userErrors {
        field
        message
      }
    }
  }
`

export const USER_DELETE_MUTATION = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id) {
      id
    }
  }
`

export const USER_INVITE_MUTATION = gql`
  mutation InviteUser($id: String!) {
    inviteUser(id: $id) {
      id
    }
  }
`

export const USER_RESTORE_MUTATION = gql`
  mutation RestoreUser($id: String!) {
    restoreUser(id: $id) {
      id
    }
  }
`
export const PHONE_NUMBERS_QUERY = gql`
  query AvailablePhoneNumbersQuery($areaCode: String!, $tollFree: Boolean) {
    availablePhoneNumbers(areaCode: $areaCode, tollFree: $tollFree) {
      friendlyName
      phoneNumber
    }
  }
`
