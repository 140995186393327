import styled from "@emotion/styled";
import { Button, Text, Stack } from "@shopify/polaris";
import { ArrowLeftMinor } from "@shopify/polaris-icons";

const Container = styled.div`
  position: relative;
`;

const BackButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

export const OnboardingHeader = ({
  title = "Account setup",
  subtitle = "Step 1 of 2",
  backAction = null,
}) => {
  return (
    <>
      <Container>
        {backAction && (
          <BackButtonContainer>
            <Button icon={ArrowLeftMinor} onClick={backAction} />
          </BackButtonContainer>
        )}
        <Stack vertical spacing="none">
          <Text variant="headingMd" alignment="center">{title}</Text>
          <Text color="subdued" alignment="center">{subtitle}</Text>
        </Stack>
      </Container>
    </>
  );
};
