import { useEffect } from 'react'

import { ApolloLink, Observable } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { AppProvider } from '@shopify/polaris'
import translations from '@shopify/polaris/locales/en.json'

import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import { LocaleProvider } from 'src/contexts/locale'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import { AuthProvider, useAuth } from './auth'

import './scaffold.css'
import './styles.css'
import './index.css'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
  })
}

// FullStory.init({
//   orgId: 'o-1H25G1-na1',
//   devMode: process.env.NODE_ENV === 'development',
// })

const createLinkWithOrganization = () =>
  new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle
        Promise.resolve(operation)
          .then(async () => {
            // get org from url query param
            const organizationId = new URLSearchParams(
              window.location.search
            ).get('organizationId')
            operation.setContext({
              headers: {
                ...operation.getContext().headers,
                ...(organizationId
                  ? { 'X-TrackPath-Organization': organizationId }
                  : {}),
              },
            })
          })
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            })
          })
          .catch(observer.error.bind(observer))
        return () => {
          if (handle) handle.unsubscribe()
        }
      })
  )

const App = () => (
  <FatalErrorBoundary page={FatalErrorPage}>
    <AppProvider i18n={translations}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider>
          <RedwoodApolloProvider
            useAuth={useAuth}
            graphQLClientConfig={{
              cacheConfig: {
                addTypename: false,
                typePolicies: {
                  Query: {
                    fields: {
                      account: {
                        merge: true,
                      },
                    },
                  },
                },
              },
              link: (redwoodApolloLinks) =>
                ApolloLink.from([
                  createLinkWithOrganization(),
                  ...redwoodApolloLinks.map(({ link }) => link),
                ]),
            }}
          >
            <LocaleProvider>
              <Routes />
            </LocaleProvider>
          </RedwoodApolloProvider>
        </AuthProvider>
      </RedwoodProvider>
    </AppProvider>
  </FatalErrorBoundary>
)

export default App
