export const EXCUSED_ABSENCE_FIELDS = `
  id
  type
  startDate
  endDate
  notes
  uploadedDocuments
  minutesAllotted
  approvingManager {
    id
    email
    associate {
      id
      name
    }
  }
  deletedAt
`

export const EXCUSED_ABSENCE_QUERY = gql`
  query ExcusedAbsenceQuery($input: ExcusedAbsencePageInput) {
    excusedAbsencePage(input: $input) {
      excusedAbsences {
        ${EXCUSED_ABSENCE_FIELDS}
      }
      hasNextPage
      hasPreviousPage
    }
  }
`

export const CREATE_EXCUSED_ABSENCE_MUTATION = gql`
  mutation CreateExcusedAbsenceMutation($input: CreateExcusedAbsenceInput!) {
    createExcusedAbsence(input: $input) {
      id
      type
      startDate
      endDate
      notes
      uploadedDocuments
      approvingManager {
        id
        email
      }
    }
  }
`

export const UPDATE_EXCUSED_ABSENCE_MUTATION = gql`
  mutation UpdateExcusedAbsenceMutation($input: UpdateExcusedAbsenceInput!) {
    updateExcusedAbsence(input: $input) {
      id
      type
      startDate
      endDate
      notes
      uploadedDocuments
      approvingManager {
        id
        email
      }
    }
  }
`

export const DELETE_EXCUSED_ABSENCE_MUTATION = gql`
  mutation DeleteExcusedAbsenceMutation($id: String!) {
    deleteExcusedAbsence(id: $id) {
      id
    }
  }
`
