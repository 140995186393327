import { TRACK_FIELDS } from './tracks'

export const POLICY_RULE_SET_FIELDS = `
  id
  name
  defaultUnion
  defaultNonUnion
  councellingThreshold {
    id
    writtenWarning
    finalWarning
    probationFinalWarning
    probationTermination
    termination
    verbalWrittenWarning
    ncnsFinalWarning
    ncnsProbationFinalWarning
    ncnsProbationTermination
    ncnsTermination
    ncnsWrittenWarning
  }
  occurrenceTypes {
    id
    name
    points
    key
    enabled
  }
  probationDays
  occurrenceActiveMonths
  wrvActiveMonths
  maxConsecutiveNcnsDays
  separateConsecutiveNcns
  ncnsPointValue
  mealBreakWarningRequired
  sickDaysBeforeUnexcusedAbsence
  sickDaysRefreshMonth
  sickDaysRefreshDay
  initialSickDaysDates {
    day
    month
    amount
  }
  sickDaysBeforeOccurrenceTypes
  excusedAbsenceTypes
  occurrenceCounselingWindowDays
  occurrenceCounselingWindowStartOnEntry
  ncnsCounselingWindowDays
  ncnsCounselingWindowStartOnEntry
  wrvCounselingWindowDays
  wrvCounselingWindowStartOnEntry
  grievanceTrackingEnabled
  grievanceStepCount
  grievanceStepTimeLimits
  grievancesViewLevels
  grievancesInitiateLevels
  grievancesResolveLevels
  tracks {
    ${TRACK_FIELDS}
  }
  hasAttendanceTrack
  hasNcnsTrack
`

export const POLICY_RULE_SETS_FOR_ACCOUNT_QUERY = gql`
  query PolicyRuleSetsForAccount($accountId: String!) {
    account(id: $accountId) {
      id
      policyRuleSets {
        ${POLICY_RULE_SET_FIELDS}
      }
    }
  }
`

export const UPDATE_POLICY_RULE_SET_MUTATION = gql`
  mutation UpdatePolicyRuleSet(
    $id: String!
    $input: UpdatePolicyRuleSetInput!
  ) {
    updatePolicyRuleSet(id: $id, input: $input) {
      id
    }
  }
`

export const CREATE_POLICY_RULE_SET_MUTATION = gql`
  mutation CreatePolicyRuleSet($accountId: String!, $name: String!) {
    createPolicyRuleSet(accountId: $accountId, name: $name) {
      id
    }
  }
`
