import { Frame, TopBar } from '@shopify/polaris'

import { navigate, routes } from '@redwoodjs/router'
import { Toaster } from '@redwoodjs/web/toast'

import { useAuth } from 'src/auth'
import GATracker from 'src/components/shared/GATracker'

import logo from './logo.png'

const UnauthenticatedLayout = ({ children }) => {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated && window.location.pathname !== '/logout') {
    navigate(routes.associates())
  }

  return (
    <Frame
      topBar={<TopBar />}
      logo={{
        topBarSource: logo,
      }}
    >
      <GATracker />
      {children}
      <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />
    </Frame>
  )
}

export default UnauthenticatedLayout
