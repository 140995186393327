import { GRIEVANCE_FIELDS } from './grievances'

export const WORK_RULE_VIOLATION_FIELDS = `
  id
  workRuleId
  workRule {
    id
    class
    name
    deletedAt
  }
  workRule2Id
  workRule2 {
    id
    class
    name
  }
  workRule3Id
  workRule3 {
    id
    class
    name
  }
  class
  date
  notes
  editNotes
  signedCounselingSheetUrl
  uploadedDocuments
  createdAt
  counselingType
  counselingIssued
  counselingApprovedAt
  counselingDate
  counselingExpiredAt
  counselingExpiresOn
  counselingManager {
    id
    name
  }
  reportingManager {
    id
    name
  }
  approvalManager {
    id
    name
  }
  expiresAt
  expiresAtChangeReason
  expiresAtChangeDocumentUrl
  deletedAt
  deletedBy {
    id
    name
  }
  deleteNotes
  deleteDocuments
  grievance {
    ${GRIEVANCE_FIELDS}
  }
`

export const WORK_RULE_VIOLATION_QUERY = gql`
  query WorkRuleViolationQuery($input: WorkRuleViolationPageInput) {
    workRuleViolationPage(input: $input) {
      workRuleViolations {
        ${WORK_RULE_VIOLATION_FIELDS}
      }
    }
  }
`

export const CREATE_WORK_RULE_VIOLATION_MUTATION = gql`
  mutation CreateWorkRuleViolation($input: CreateWorkRuleViolationInput!) {
    createWorkRuleViolation(input: $input) {
      id
    }
  }
`

export const UPDATE_WORK_RULE_VIOLATION_MUTATION = gql`
  mutation UpdateWorkRuleViolation($input: UpdateWorkRuleViolationInput!) {
    updateWorkRuleViolation(input: $input) {
      id
    }
  }
`

export const DELETE_WORK_RULE_VIOLATION_MUTATION = gql`
  mutation DeleteWorkRuleViolation(
    $id: String!
    $notes: String
    $documents: [String!]
  ) {
    deleteWorkRuleViolation(id: $id, notes: $notes, documents: $documents) {
      id
    }
  }
`

export const WORK_RULES_QUERY = gql`
  query GetWorkRules($accountId: String!, $associateId: String) {
    workRules(accountId: $accountId, associateId: $associateId) {
      id
      name
      class
      deletedAt
    }
  }
`
